import React, { useState, useEffect } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import AppleIcon from "../../../assests/image/apple-store.png";
import playStoreIcon from "../../../assests/image/app-store.png";
import paymentIcons from "../../../assests/image/payment-icons.png";

import { FOOTER_API } from "../../../Constants";
import {
  facebook,
  insta,
  linkedin,
  pintrest_icon,
  unfit_white_logo,
  location_icon,
  youtube,
} from "../../../utils/image";
import white_location from "../../../assests/icons/Location.png";
import PhoneLogo from "../../../assests/icons/Call.svg";
import MailLogo from "../../../assests/icons/Mail.svg";
import twiterIcon from "../../../assests/icons/twiterIcon.svg";

export const fetchFooterData = async () => {
  const payload = new URLSearchParams({
    Pagination: "",
    groupCategoryId: "0",
    LevelType: "shopDashboard",
  });

  const response = await fetch(FOOTER_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  return data || [];
};

const Footer = () => {
  // State for toggling footer visibility
  const [isActive, setIsActive] = useState(false);

  // Toggle function to show/hide footer
  const handleRemoveFooter = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };

  //  // Scroll to bottom when footer becomes visible
  //  useEffect(() => {
  //   if (isActive) {
  //     window.scrollTo({
  //       top: document.documentElement.scrollHeight, // Scroll to bottom
  //       behavior: 'smooth', // Smooth scrolling
  //     });
  //   }
  // }, [isActive]); // This effect runs when isActive changes

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when location changes
  }, [location]);

  return (
    <div className="footer" id="scrollableDiv">
      <div
        className="mobile__footer__hide orange"
        onClick={() => handleRemoveFooter()}
      >
        {isActive ? "Know less about unFIT Mart" : "Know more about unFIT Mart"}
      </div>
      <footer className={`orange__background ${isActive ? "" : "hidden"}`}>
        
        <div className="footer__wrapper page-width">
          <div className="footer__container">
            <div className="footer__logo-items">
              <div className="logo__item">
                <Link to="/">
                  <img src={unfit_white_logo} alt="unfit Logo" />
                </Link>
              </div>
              <div className="about_us-content">
                <p>
                  Your go-to destination for genuine, high-quality health and
                  wellness products. Explore our reliable range and kickstart
                  your journey to better health with confidence!
                </p>
              </div>
              <div className="footer_social">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/unfitmart/"
                      target="_blank"
                    >
                      <img src={insta} alt="instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/unfitech/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/Unfitmart">
                      <img src={twiterIcon} alt="twiter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/unfitapp/"
                      target="_blank"
                    >
                      <img src={facebook} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@unFitmart"
                      target="_blank"
                    >
                      <img src={youtube} alt="youtube" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://in.pinterest.com/unFITapp/"
                      target="_blank"
                    >
                      <img src={pintrest_icon} alt="pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__about-us">
              <div className="about_us-content">
                <p className="footer__heading__para">About Us</p>
                <p>
                  <li>
                    <img src={white_location} className="icon" />
                    <span className="footer__about__content">
                      Isquare Corporate Park, 1114-1116, Science City,
                      Panchamrut Bunglows II, Sola, Ahmedabad, Gujarat 380060
                    </span>
                  </li>
                </p>
              </div>
              <div className="contact_us-content">
                <ul>
                  {/* <li>
                <img src={LocationLogo} className="icon" />
                  <div>Isquare Corporate Park, 1114-1116, Science City, Panchamrut
                  Bunglows II, Sola, Ahmedabad, Gujarat 380060</div>
                </li> */}
                <li>
                  <img src={PhoneLogo} className="icon" alt="icon" />
                  <Link to="tel:1800 202 5157">
                    Toll Free Number : 1800 202 5157
                  </Link>
                </li>
                <li>
                  <img src={MailLogo} className="icon" alt="icon"/>
                  <Link to="mailto:support@unfitmart.com">support@unfitmart.com</Link>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="footer__menus-items">
            <p className="footer__heading__para">Company</p>
            <ul className="footer__menu-item">
            <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/shipping-policy">Shipping Policy</Link>
              </li>
              
              {/* <li>
                <Link to="/terms-condition">Service Refund Policy</Link>
              </li> */}
          
              <li>
                <Link to="/terms-condition">Terms and Condition</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="footer__menus-items">
            <p className="footer__heading__para">Top Category</p>
            <ul className="footer__menu-item">
              <li>
                <Link to="/category/Sports+Nutrition?viewId=1">Sport Nutrition</Link>
              </li>
              <li>
                <Link to="/category/Health+Supplement?viewId=2">Health Supplement</Link>
              </li>
              <li>
                <Link to="/category/Hair,+Skin+&+Nails?viewId=3">Hair, Skin & Nails</Link>
              </li>
              <li>
                <Link to="/category/Women's+Health?viewId=7">Women's Health</Link>
              </li>
              <li>
                <Link to="/category/Weight+Management?viewId=5">Weight Management</Link>
              </li>
              
            </ul>
          </div>
          <div className="footer__subscribe-item">
          <p className="footer__heading__para">App Download</p>
          <div className="footer__logoes">
              <div className="apple__item">
                <a href="https://apps.apple.com/in/app/unfit/id6476141751"  target="_blank">
                  <img src={AppleIcon} alt="Apple Store" />
                </a>
              </div>
              <div className="playstore__item">
                <a href="https://play.google.com/store/apps/details?id=com.ext.bolt&hl=en_IN"  target="_blank">
                  <img src={playStoreIcon} alt="Google Play Store" />
                </a>
              </div>
            </div>
            
            {/* <div className="footer_news">
              <h5>Subscribe</h5>
              <p>Subscribe to stay tuned for new web design and latest updates. Let's do it! </p>
              <form className="newslatter">
                <div className="input_field">
                  <input type="email" placeholder="Enter your email Address" />
                </div>
                <div className="input_btn">
                  <button>Subscribe</button>
                </div>
              </form>
            </div> */}
            </div>
          </div>
        </div>
        <div className="footer__copyright-container page-width">
          <div className="footer__copyright caption">
            <small className="copyright__content">
              Copyright © 2024{" "}
              <a href="https://evishealthcare.com/" target="_blank">
                EVIS HEALTHCARE LIMITED
              </a>{" "}
              . All rights reserved.
            </small>
            <small className="copyright__content">
              <img src={paymentIcons} alt="payment img"></img>
            </small>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
