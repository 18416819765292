import { useEffect, useState } from "react";
import OrderHistoryItem from "./orderHistoryItem";
import "./orderHistoryItem.css";
import LoadingCard from "../../../common/loader/loadingCard";
import { fetchRegisterId, WEB_ORDER_HISTORY } from "../../../../Constants";
import { noorder } from "../../../../utils/image";
import { Link } from "react-router-dom";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
const OrderHistory = ({ setOrderID_details }) => {
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addAddress = async () => {
    try {
      let resgisterID = fetchRegisterId();
      const response = await fetch(WEB_ORDER_HISTORY, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: resgisterID,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const orderHistory = await response.json();
      setOrderHistoryData(orderHistory.Result);
      let data = orderHistory.Result[0];

      moengage.track_event("Orders_History_Page_Viewed", {
        "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
        

        
      });
    } catch (error) {
      //console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    addAddress();
  }, []);

  return (
    <div>
      {orderHistoryData?.length && !loading ? (
        <div className="orderHistoryWrapper">
          <div className="order_header_txt">
            <h5> My Order ({orderHistoryData.length})</h5>
          </div>
          <div className="historyItemContentBox">
            {orderHistoryData &&
              orderHistoryData.map((historyDeatils) => (
                <OrderHistoryItem
                  historyDeatils={historyDeatils}
                  key={historyDeatils.OrderId}
                  setOrderID_details={setOrderID_details}
                />
              ))}
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <>
              <div className="loading">
                <LoadingCard />
              </div>
            </>
          ) : (
            <>
              <div className="not_data_found_wrap">
                <div className="main_data_container">
                  <div className="img_found_wrap">
                    <img src={noorder} alt="img" />
                  </div>
                  <div className="not_found_text">
                    <h5>No orders yet</h5>
                    <Link to="/" className="btn_primary">
                      Start Shopping
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default OrderHistory;
