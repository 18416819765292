import React from 'react'
import { loader_icon } from '../../../utils/image'

const CustomLoader = () => {
 
  return (
    <div className="page_loading"><img src={loader_icon} alt="page_loader" /></div>
  )
}

export default CustomLoader