import React, { useState, useEffect,useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../homeSlider/hmSlider.css";
import Loader from "../../loader/loadingCard";
import { fetchBannerData } from "../../../../api/HomePageApi";
import { Link, useNavigate } from "react-router-dom";
// import befitvideo from "../../../../assests/video/befitvideo.webm";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
import CustomLoader from "../../customLoader/customLoader";
import urlReplace from "../../../../services/urlReplace";

const DefaultSliderImages = [
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 1",
  },
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 2",
  },
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 3",
  },
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 4",
  },
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 5",
  },
];

const HmSlider = ({ data, screenName, eventName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [url, setUrl] = useState();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0); // Track the active slide
  const videoRefs = useRef([]); // Create a ref to store video elements

  let desktop_video = document.querySelector(".desktop__video");
  let mobile_video = document.querySelector(".mobile__video");
  let play_Video = document.querySelector(".play_labell");
  let handlePlayBtn = () => {
    desktop_video?.play();
    play_Video?.classList.add("hide");
  };

  //console.log(data);
  useEffect(() => {
    const loadBannerData = async () => {
      try {
        //console.log(data)

        if (data.length > 0) {
          const formattedImages = data.map((banner) => ({
            mobileSrc: banner.Image?.trim(),
            desktopSrc:
              banner.WebImage?.trim(),
            alt: banner.alt || "Default Image",
            Type: banner.Type,
          }));
          //console.log(formattedImages)
          setSliderImages(formattedImages);
        } else {
          // Use default images if no banners are found
          //console.log(DefaultSliderImages)
          //setSliderImages(DefaultSliderImages);
        }
        setImagesLoaded(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        
      } catch (error) {
        console.error("Error fetching banner data:", error);
        // If fetching fails, fall back to default images
        setSliderImages(DefaultSliderImages);
       
       
        setImagesLoaded(false);
      }
    };
    setBannerData(data);
    loadBannerData();
  }, [data]);


  const pauseAllVideos = () => {
    videoRefs.current.forEach((video) => {
      //console.log("Here",video && video.play)
      if (video && video.play) {

        //console.log("Here",video && video.play)
        desktop_video?.pause(); // Pause video if it's playing
      }
    });
  };

  const handleSlideChange = (index) => {
   
    setActiveIndex(index);
    pauseAllVideos(); 
  };


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    // touchThreshold: 100,
    afterChange: handleSlideChange,
    pauseOnHover: false,
    pauseOnFocus: false,

  };

  const handleBannerClick = (bannerDataItem, index) => {
    //console.log(bannerDataItem)
    let urlT;
    if (bannerDataItem.Type === "offer_store") {
      navigate(`/offer_store?id=${bannerDataItem.TypeId || 0}`);

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Store Name": bannerDataItem.OfferTitle,
        "Store ID": bannerDataItem.TypeId,
        Index: index + 1,
        "Screen Name": screenName,
      });
    } else if (bannerDataItem.Type === "brand") {
      navigate(
        `/brands/` +
          `${bannerDataItem.BrandName || "default-brand"}`
            .replace(/--+/g, "")
            ?.replace(/[^a-zA-Z0-9 -]/g, "")
            .replace(" ", "-")
            ?.replace(/\s+/g, "-")
            .toLowerCase() +
          `?id=${bannerDataItem.TypeId || 0}`
      );

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Brand Name": bannerDataItem.BrandName,
        "Banner URL": bannerDataItem.WebImage,
        Index: index + 1,
        "Screen Name": screenName,
      });
    } else if (bannerDataItem.Type === "parent_category") {
      navigate(`/category/${bannerDataItem.ParentCategoryName}`);

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Category Name": bannerDataItem.ParentCategoryName,
        "Banner URL": bannerDataItem.WebImage,
        Index: index + 1,
        "Screen Name": screenName,
      });
    } else if (bannerDataItem.Type === "product") {
      const variant1 = urlReplace(bannerDataItem.Variant1Value);
      const variant2 = urlReplace(bannerDataItem.Variant2Value);
      const prodName =  urlReplace(bannerDataItem?.ProductName)

      navigate(
        `/product/${
          bannerDataItem.USIN ? bannerDataItem.USIN : "undefined"
        }/${prodName}/${variant1}/${variant2}?productid=${
          bannerDataItem.ProductId
        }`
      );

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Product Name": bannerDataItem?.ProductName,
        [bannerDataItem.Variant1Name ? bannerDataItem.Variant1Name : ""]:
          bannerDataItem.Variant1Value,
        ...(bannerDataItem.Variant2Name !== "" && {
          [bannerDataItem.Variant2Name]: bannerDataItem.Variant2Value,
        }),
        USIN: bannerDataItem.USIN ? bannerDataItem.USIN : "",
        "Product ID": bannerDataItem.ProductId,
        "Banner URL": bannerDataItem.WebImage,
        Index: index + 1,
        "Screen Name": screenName,
      });
    } else if (bannerDataItem.Type === "sub_category") {
      navigate(
        `/category/${bannerDataItem.ParentCategoryName.replace(
          /%20/g,
          "+"
        )?.replace("%26", "&")}/${bannerDataItem.SubCategoryName?.replace(
          /%20/g,
          "+"
        )?.replace("%26", "&")}?id=${bannerDataItem?.SubCategoryId}`
      );

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Category Name": bannerDataItem.ParentCategoryName,
        "Sub Category Name": bannerDataItem.SubCategoryName,
        "Banner URL": bannerDataItem.WebImage,
        Index: index + 1,
        "Screen Name": screenName,
      });
    } else if (bannerDataItem.Type === "link") {
      // locati  bannerDataItem.WebUrl
      //debugger;

      window.location.href = bannerDataItem.WebUrl;

      moengage.track_event(eventName, {
        ID: Cookies.get("Otp")
          ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
          : "",
        "Banner URL": bannerDataItem.WebImage,
        Index: index + 1,
        "Screen Name": screenName,
      });
    }

    //console.log(urlT)
    //debugger
    //  setUrl( urlT)
  };



  
  return (
    <div className="hm__slider__wrapper">
   
      {imagesLoaded && (
        <div className="hm__slider__container page-width padding-lr20">
          {sliderImages.length === 1 ? (
            <div className="hm__slide__item">
              <div onClick={() => handleBannerClick(bannerData[0], 0)}>
           
                {!sliderImages[0].desktopSrc?.endsWith("mp4") ? (
                  <picture>
                    <source
                      media="(min-width: 768px)"
                      srcSet={`${sliderImages[0].desktopSrc} 768w, ${sliderImages[0].desktopSrc} 1024w`}
                    />
                    <img
                      className="desktop-img play_labell"
                      src={sliderImages[0].desktopSrc}
                      alt={sliderImages[0].alt}
                    />
                    <source
                      media="(max-width: 767px)"
                      srcSet={`${sliderImages[0].mobileSrc} 320w, ${sliderImages[0].mobileSrc} 640w`}
                    />
                    <img
                      className="mobile-img play_labell"
                      src={sliderImages[0].mobileSrc}
                      alt={sliderImages[0].alt}
                    />
                  </picture>
                ) : (
                  <video
                    onClick={() => handlePlayBtn()}
                    id="video"
                    src={sliderImages[0].desktopSrc}
                    className="store-image desktop__video"
                    controls
                    
                    muted
                    loop
                  />
                )}
                {/* <picture>
                  <source media="(min-width: 768px)" srcSet={`${sliderImages[0].desktopSrc} 768w, ${sliderImages[0].desktopSrc} 1024w`} />
                  <img className="desktop-img" src={sliderImages[0].desktopSrc} alt={sliderImages[0].alt} />
                  <source media="(max-width: 767px)" srcSet={`${sliderImages[0].mobileSrc} 320w, ${sliderImages[0].mobileSrc} 640w`} />
                  <img className="mobile-img" src={sliderImages[0].mobileSrc} alt={sliderImages[0].alt} />
                </picture> */}
              </div>
            </div>
          ) : (
            <Slider {...settings}>
              {sliderImages.length > 0 ? (
                
                sliderImages.map((image, index) => (
                  <div key={index} className="hm__slide__item">
                    <div
                      onClick={() =>
                        handleBannerClick(bannerData[index], index)
                      }
                    >
                      {!image.desktopSrc.endsWith("mp4") &&
                      !image.mobileSrc.endsWith("mp4") ? (
                        <picture>
                          <source
                            media="(min-width: 768px)"
                            srcSet={`${image.desktopSrc} 768w, ${image.desktopSrc} 1024w`}
                          />
                          <img
                            className="desktop-img play_labell"
                            src={image.desktopSrc}
                            alt={image.alt}
                          />
                          <source
                            media="(max-width: 767px)"
                            srcSet={`${image.mobileSrc} 320w, ${image.mobileSrc} 640w`}
                          />
                          <img
                            className="mobile-img play_labell"
                            src={image.mobileSrc}
                            alt={image.alt}
                          />
                        </picture>
                      ) : (
                        <video
                          id="video"
                          className=" desktop__video"
                          controls
                         
                          onClick={() => handlePlayBtn()}
                          ref={(el) => (videoRefs.current[index] = el)} // Assign video element to the ref
                        >
                          {/* Video for desktop */}
                          <source
                            src={image.desktopSrc}
                            media="(min-width: 768px)"
                            type="video/mp4"
                          />

                          {/* Video for mobile */}
                          <source
                            src={image.mobileSrc}
                            media="(max-width: 768px)"
                            type="video/mp4"
                          />

                          {/* Fallback content */}
                        </video>
                      )}
                      {/* <picture>
                        <source media="(min-width: 768px)" srcSet={`${image.desktopSrc} 768w, ${image.desktopSrc} 1024w`} />
                        <img className="desktop-img" src={image.desktopSrc} alt={image.alt} />
                        <source media="(max-width: 767px)" srcSet={`${image.mobileSrc} 320w, ${image.mobileSrc} 640w`} />
                        <img className="mobile-img" src={image.mobileSrc} alt={image.alt} />
                      </picture> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No images available to display</p>
              )}
            </Slider>
          )}
        </div>
      )}
    </div>
  );
};

export default HmSlider;