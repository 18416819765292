import React, { useState, useEffect, useRef } from "react";
import CartItem from "./cartItem";
import CryptoJS from "crypto-js";
import "./cart.css";
import CartButtons from "../../common/cartButtons";
import Imoji from "../../../assests/icons/emoji.png";
import CartImage from "../../../assests/image/empty-cart.png";
import OfferImage from "../../../assests/image/AppIcon.png";

import stroke3 from "../../../assests/image/Stroke3.png";
import { Link, useNavigate } from "react-router-dom";
import Login from "../customer/login/login";
import Account from "../customer/account/account";
import { toast } from "react-toastify";
import { getCookie } from "../../../utils/cookieUtils"; // Adjust path to your cookieUtils
import {
  fetchRegisterId,
  fetchUserData,
  WEB_CARTLIST,
  WEB_ADDRESS,
  PROMO_CODE,
  WEB_ORDER_HISTORY,
} from "../../../Constants";
import Cookies from "js-cookie";
import {
  coupon_icon,
  cross_black,
  green_check,
  off_01,
  right_arrow_bk,
  right_icon,
} from "../../../utils/image";
import CustomLoader from "../../common/customLoader/customLoader";
import UnRegisterCart from "./UnRegisterCart";
import UnRegisterTotal from "./UnRegisterTotal";
import moengage from "@moengage/web-sdk";

const CartPage = ({ setCountItems }) => {
  const [cookieCartItems, setCookieCartItems] = useState([]);
  const [isApplyCoupon, setisApplyCoupon] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [appliedCouponTitle, setAppliedCouponTitle] = useState("");
  const [orderLength, setOrderLength] = useState(null);

  const [PromoCodeDetails, setPromoCodeDetails] = useState({
    isOpen: false,
  });
  const [PromoCode, setPromoCode] = useState(null);
  //const [addres, setAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState({
    Result: [],
    FinalPayableAmount: 0,
    ServiceFee: 0,
    discount: 0,
    SubTotal: 0,
  });
  const [displayLoginPages, setDisplayLoginPages] = useState({
    displayLogin: false,
    displayAccountPage: false,
    displayAddressPage: false,
    accountVerified: false,
  });
  const [countItems, setCountItem] = useState([]);
  const [PromocodeError, setPromocodeError] = useState(false);
  const [userDataObj, setUserDataObj] = useState(null);
  const [inputChange, setAppliedCouponed] = useState("");

  let isInitialRenderAppliedCoupon = useRef(true);
  // State for address form

  const cartItemRefs = useRef([]);
  const navigate = useNavigate();
  let modal = document.getElementById("myModal");

  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };

  // Define your secret key (ensure it’s the same for encryption and decryption)
  const secretKey = "8888-aaaa-6666-cccc";

  // Encrypt data function
  const encryptData = (data) => {
    if (!data) return null; // Guard clause for undefined or null data
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  // Decrypt data function
  const decryptData = (cipherText) => {
    if (!cipherText) return null; // Guard clause for undefined or null cipherText
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  // Order data and history starts //
  const addAddress = async () => {
    try {
      let resgisterID = fetchRegisterId();
      const response = await fetch(WEB_ORDER_HISTORY, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: resgisterID,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const orderHistory = await response.json();
      setOrderLength(orderHistory?.Result?.length);
      //console.log(orderHistory?.Result?.length,"orderHistory",orderLength)
    } catch (error) {
      //console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    addAddress();
  }, []);

  // order history ends //

  const fetchPromoCode = () => {
    const fetchPromoCode = async () => {
      try {
        const RegisterId = fetchRegisterId();
        const payload = new URLSearchParams({
          RegisterId,
        });
        const response = await fetch(PROMO_CODE, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }

        const data = await response.json();
        setPromoCode(data.Result);
      } catch (err) {
        // setError(err.message);
      } finally {
      }
    };
    fetchPromoCode();
  };

  useEffect(() => {
    const fetchUserData = () => {
      const userData = Cookies.get("userData")
        ? JSON.parse(Cookies.get("userData"))
        : null;
      setUserDataObj(userData);
    };

    fetchUserData();
  }, []);

  const fetchCartItems = async () => {
    try {
      const RegisterId = fetchRegisterId();
      let response;
      //console.log("appliedCoupon",appliedCoupon)
      if (RegisterId) {
        // Prepare the data to be sent
        const requestData = {
          RegisterId: RegisterId,
          CouponApplyCode: appliedCoupon,
          PaymentType: "Prepaid",
          ApplicationType: "web",
        };

        // Encrypt the request data
        const encryptedData = encryptData(requestData);
        //console.log(decryptData(encryptedData))

        // Send the encrypted data in the request body
        response = await fetch(WEB_CARTLIST, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(decryptData(encryptedData)),
          // body: encryptedData,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch cart items! Status: ${response.status}`
          );
        }

        const cartItemsData = await response.json();

        if (
          cartItemsData.Status &&
          ((!cartItemsData.Promocode &&
            cartItemsData?.StatusMessage === "Cart List") ||
            (cartItemsData.Promocode &&
              cartItemsData?.StatusMessage === "Coupon Code Applied"))
        ) {
          //console.log(cartItemsData.Promocode,cartItemsData.Status ,cartItemsData)
          setPromocodeError("");
          setItems(cartItemsData || {});
          setCountItem(cartItemsData.Result);
        } else if (cartItemsData.Status && !cartItemsData.Promocode) {
          if (cartItemsData?.StatusMessage === "Coupon Code Invalid") {
            setItems(cartItemsData || {});
            setCountItem(cartItemsData.Result);
            setPromocodeError(cartItemsData?.StatusMessage);
            handleRemoveCoupon();
          } else if (
            cartItemsData?.StatusMessage === "Coupon code already used"
          ) {
            //toast.error("Coupon Code Already Used");
            setItems(cartItemsData || {});
            setCountItem(cartItemsData.Result);
            setPromocodeError(cartItemsData?.StatusMessage);
            handleRemoveCoupon();
          }
        }
      } else {
        // Handle the case when RegisterId is not present
        const cartItemsData = getCookie("cartData");
        if (cartItemsData) {
          setCountItems(JSON.parse(cartItemsData)?.length);
        }
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDataObj) {
      fetchPromoCode();
      fetchCartItems();
    }
  }, [userDataObj]);

  useEffect(() => {
    cartItemRefs.current = items.Result?.map(() => React.createRef()) || [];
  }, [items.Result]);

  useEffect(() => {
    items.Result?.forEach((item, index) => {
      if (cartItemRefs.current[index]?.current) {
        cartItemRefs.current[index].current.setAttribute(
          "data-line-item",
          item.id
        );
      }
    });
  }, [items.Result]);

  useEffect(() => {
    if (
      displayLoginPages.displayLogin ||
      displayLoginPages.displayAddressPage
    ) {
      modal.style.display = "block";
    }
  }, [displayLoginPages]);

  const togglePromoCodePopUp = (promo) => {
    setPromoCodeDetails((prev) => ({ ...promo, isOpen: !prev.isOpen }));
  };

  const checkoutBtn = () => {
    if (!fetchRegisterId()) {
      setDisplayLoginPages({ ...displayLoginPages, displayLogin: true });
      modal.style.display = "block";
    } else {
      navigate("/checkout", { state: { appliedCoupon } });
    }
  };

  const toggleApplyCoupon = () => {
    if (fetchRegisterId()) {
      setisApplyCoupon(!isApplyCoupon);
    } else {
      setDisplayLoginPages({ ...displayLoginPages, displayLogin: true });
      modal.style.display = "block";
    }
    document.querySelector(".header__wrapper")?.classList.remove("sticky");
    document.querySelector(".main-nav")?.classList.remove("sticky");
  };
  useEffect(() => {
    if (isApplyCoupon) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [isApplyCoupon]);

  const handlePromoCodeAdd = (title, promoCode) => {
    console.log(promoCode);
    setAppliedCouponTitle(title);
    setAppliedCoupon(promoCode);
    toggleApplyCoupon();
    setPromocodeError("");
  };

  const handleRemoveCoupon = () => {
    //console.log("Removing")
    setAppliedCouponTitle("");
    setAppliedCoupon("");
    setAppliedCouponed("");
  };
  const isCartEmpty = items.Result?.length === 0;

  let storedCartData = "";

  //   // cookies cart data //
  //   // Retrieve the stored cart data from the cookie
  storedCartData = getCookie("cartData");
  if (storedCartData) {
    storedCartData = JSON.parse(storedCartData);
  }

  //console.log(storedCartData,"storedCartData")
  const PromoCodeInput = PromoCode?.find(
    (coupon) => coupon?.CouponCode === appliedCoupon
  );

  useEffect(() => {
    console.log(appliedCoupon, PromocodeError);
    if (isInitialRenderAppliedCoupon.current) {
      // On initial render, just set the ref to false
      //console.log(appliedCoupon,PromocodeError)
      isInitialRenderAppliedCoupon.current = false;

      return;
    }

    if (
      PromocodeError !== "Coupon Code Applied" &&
      PromocodeError !== "Coupon Code Invalid" &&
      PromocodeError !== "Coupon code already used"
    ) {
      fetchCartItems();
    }

    if (appliedCoupon === PromoCodeInput?.CouponCode) {
      fetchCartItems();
    }
  }, [appliedCoupon]);

  if (loading) {
    // return <CustomLoader />;
  }

  let handleCouponChange = (event) => {
    setAppliedCouponed(event.target.value);
    let promoInputError = document.querySelector(".promocode__input__error");
    if (event.target.value === "") {
      promoInputError?.classList?.add("hide");
    }
  };

  if (orderLength === 0) {
    document.querySelectorAll(".add_coupon_code")[1]?.classList.remove("hide");
  }

  const handleMoengageAppEvent = () => {
    moengage.track_event("App_Offer_Price_Clicked", {
      ID: Cookies.get("Otp")
        ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`
        : "",

      "Screen Name": "Cart Page",
    });
  };

  return (
    <div>
      {countItems?.length > 0 || storedCartData?.length > 0 ? (
        <>
          <div className="cart__wrapper">
            <div className="page-width">
              <div className="cart_inner_wrap">
                <div className="cart__heading">
                  <h2>
                    My cart (
                    <span>{countItems?.length || storedCartData?.length}</span>)
                  </h2>
                </div>

                <div className="main__cart__container">
                  <div className="cart__container cart_left_sec">
                    <div className="cart-item">
                      <div
                        className="cart__item__contents"
                        ref={cartItemRefs.current[0]}
                      >
                        {fetchRegisterId() ? (
                          <CartItem
                            setCountItems={setCountItems}
                            setCountItem={setCountItem}
                            countItems={countItems}
                            setAppliedCoupon={setAppliedCoupon}
                            appliedCoupon={appliedCoupon}
                            setItems={setItems}
                          />
                        ) : (
                          <UnRegisterCart
                            setCountItems={setCountItems}
                            cookieCartItems={cookieCartItems}
                            setCookieCartItems={setCookieCartItems}
                          />
                        )}
                        {countItems?.length > 0 && <CartButtons />}
                      </div>
                    </div>
                  </div>
                  <div className="cart__container cart_right_sec">
                    {/* <Link  className="offerBox" to={"https://unfitmart.page.link/WoC9KsaNaBX79GRV7"} onClick={() => handleMoengageAppEvent()}>
                      <div className="offer__item__image"><img src={OfferImage} className=""/></div>
                      <div className="offerText">
                        <div className="text1">  Download the App, Get products for just ₹1</div>
                        <div className="flex"> 
                          <div className="text2"  >Click Here</div> 
                           <img src={stroke3} className="small__icon"/> </div>
                      </div>
                    
                      </Link> */}
                    <div className="cart__discount__item">
                      <div
                        className="add_coupon_code"
                        onClick={toggleApplyCoupon}
                      >
                        <div className="apply_coupon_code">
                          <img src={coupon_icon} alt="coupon" />
                          <h6>Apply Coupon</h6>
                          <img className="cp_img_arrow" src={right_arrow_bk} />
                        </div>
                      </div>
                      {PromocodeError && (
                        <div className="red">{PromocodeError}</div>
                      )}

                      {!PromocodeError && appliedCoupon && (
                        <div className="add_coupon_code applied_code">
                          <div className="apply_coupon_code ">
                            <img src={green_check} alt="coupon" />
                            <h6>{appliedCoupon}</h6>
                            <span className="sup" onClick={handleRemoveCoupon}>
                              Remove
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {fetchRegisterId() ? (
                      <>
                        <div className="cart__final__total">
                          <div className="cart_top_item">
                            <div className="cart__amount__pay payble-ammount">
                              <div className="cart__final__price__heading ">
                                MRP Total
                              </div>
                              <div className="cart__final__price__amount">
                                ₹
                                {
                                  /* {!appliedCoupon
                                  ? total.MRPTotal.toFixed(2) || 0*/
                                  items.TotalMrp?.toFixed(2) || 0
                                }
                              </div>
                            </div>
                            <div className="cart__amount__pay service-fee">
                              <div className="cart__final__price__heading ">
                                Discount On MRP
                              </div>
                              <div className="cart__final__price__amount discount__price">
                                -₹
                                {
                                  /* {!appliedCoupon
                                  ? total.DiscountOnMRP.toFixed(2) || 0.00 */
                                  (items.TotalMrp - items.SubTotal)?.toFixed(
                                    2
                                  ) || 0.0
                                }
                              </div>
                            </div>
                            <div className="cart__amount__pay discount">
                              <div className="cart__final__price__heading ">
                                Offer Applied
                              </div>
                              <div className="cart__final__price__amount discount__price">
                                -₹
                                {
                                  /* {!appliedCoupon
                                  ?  total.OfferApplied.toFixed(2) */
                                  items?.CouponDiscount?.toFixed(2) || 0.0
                                }
                              </div>
                            </div>
                            {items?.PrepaidDiscountAmount !== "" && (
                              <div className="cart__amount__pay service-fee">
                                <div className="cart__final__price__heading ">
                                  Prepaid Discount
                                </div>
                                <div className="cart__final__price__amount  light-green">
                                  {/* ₹{total.ShippingHandling || 0} */}-₹
                                  {items?.PrepaidDiscountAmount || 0}
                                  {/* NEW PARAMETER */}
                                </div>
                              </div>
                            )}

                            <div className="cart__amount__pay service-fee">
                              <div className="cart__final__price__heading ">
                                Shipping & Handling
                              </div>
                              <div className="cart__final__price__amount ">
                                {
                                  /* {!appliedCoupon
                                  ? total.ShippingHandling.toFixed(2) || 0.00 */
                                  items?.DelhiveryCharges?.toFixed(2) || 0.0
                                }
                              </div>
                            </div>
                            <div className="cart__amount__pay total">
                              <div className="cart__final__price__heading">
                                Total
                              </div>
                              <div className="cart__final__price__amount discount__price">
                                ₹
                                {
                                  /* {!appliedCoupon
                                  ? total.finalAmoutAfterDiscount.toFixed(2) || 0 */
                                  items?.FinalPayableAmount?.toFixed(2) || 0
                                }
                              </div>
                            </div>
                          </div>
                          {items.TotalMrp -
                            items.SubTotal +
                            items.CouponDiscount >
                            0 && (
                            <div className="cart__amount__pay saved-amount">
                              <div className="save_img">
                                <img src={Imoji} alt="imoji" />
                              </div>

                              <div className="cart__save__amount">
                                You have saved ₹
                                {items.TotalMrp -
                                  items.SubTotal +
                                  items.CouponDiscount}{" "}
                                on this order
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="cart__final__container">
                          <div className="cart_total_main">
                            <div className="g_total_title">
                              Grand Total{" "}
                              <span>
                                ₹
                                {
                                  /* !appliedCoupon
                                  ? total.finalAmoutAfterDiscount.toFixed(2) || 0 */
                                  items?.FinalPayableAmount?.toFixed(2) || 0
                                }
                              </span>
                            </div>
                            <div className="cart_checkout_main">
                              <div
                                className={`submit__buttons btn_primary flex ${
                                  isCartEmpty ? "" : ""
                                }`}
                                onClick={checkoutBtn}
                                style={{
                                  opacity: isCartEmpty ? 0.8 : 1,
                                  cursor: "pointer",
                                }}
                              >
                                Check Out
                                <img src={right_icon} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <UnRegisterTotal
                        existingCartData={cookieCartItems}
                        isCartEmpty={isCartEmpty}
                        checkoutBtn={checkoutBtn}
                      />
                    )}
                  </div>
                </div>
                {PromoCodeDetails?.isOpen && (
                  <div className="popup_main">
                    <div
                      className="popup_overlay"
                      onClick={togglePromoCodePopUp}
                    ></div>
                    <div className="popup_wraper">
                      <div className="pop_up_title">
                        <h5>Coupon Details</h5>
                        <div
                          className="cs_pop_close"
                          onClick={togglePromoCodePopUp}
                        >
                          <img src={cross_black} alt="svg icon" />
                        </div>
                      </div>
                      <div className="popup_body_wrap">
                        <div className="coupon_code">
                          <div className="coupon_code_label">
                            <h5>{PromoCodeDetails?.Title}</h5>
                            <p>{PromoCodeDetails?.Description}</p>
                          </div>
                          <div className="coupon_code_tc">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: PromoCodeDetails?.TermsCondition,
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div id="myModal" className="modal">
                  {displayLoginPages.displayLogin && (
                    <Login
                      setDisplayLoginPages={setDisplayLoginPages}
                      displayLoginPages={displayLoginPages}
                    />
                  )}

                  {displayLoginPages.displayAccountPage &&
                    !displayLoginPages.displayLogin && (
                      <div className="modalWrapper">
                        <div className="modalContent">
                          <Account
                            displayLoginPages={displayLoginPages}
                            setDisplayLoginPages={setDisplayLoginPages}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className={`coupon_sidebar ${isApplyCoupon && "active"}`}>
            <div className="coupon_overlay" onClick={toggleApplyCoupon}></div>
            <div className="coupon_side_wrap">
              <div className="coupon_items">
                <div className="add_cop_name">
                  <h6>Apply Coupon</h6>
                  <div className="close_cp_bar" onClick={toggleApplyCoupon}>
                    <img src={cross_black} alt="svg icon" />
                  </div>
                </div>
                <div className="cart__discount__input flex">
                  {appliedCoupon === PromoCodeInput?.CouponCode ? (
                    <input
                      type="text"
                      placeholder="Add Coupon Code"
                      value={appliedCoupon}
                      onChange={handleCouponChange}
                      disabled="disabled" // Add this to handle changes
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Add Coupon Code"
                      value={inputChange} // Provide an empty string as default
                      onChange={handleCouponChange} // Add this to handle changes
                    />
                  )}

                  <div className="cart__discount__apply__btn">
                    {appliedCoupon === PromoCodeInput?.CouponCode ? (
                      <span className="sup" onClick={handleRemoveCoupon}>
                        Remove
                      </span>
                    ) : (
                      <span onClick={() => handlePromoCodeAdd("", inputChange)}>
                        Apply
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="coupon_code_inner">
                {PromoCode?.map((promo) => {
                  return (
                    <div key={promo.id} className="coupon_main_card">
                      <div
                        className="apply_btn"
                        onClick={() =>
                          handlePromoCodeAdd(promo.Title, promo.CouponCode)
                        }
                      >
                        <p>
                          {orderLength === 0
                            ? appliedCoupon === promo.CouponCode
                              ? "Applied"
                              : "Apply"
                            : appliedCoupon === promo.CouponCode
                            ? "Applied"
                            : "Apply"}
                        </p>
                      </div>
                      <div className="coupon_img">
                        <img src={promo?.Image || off_01} alt="promo img" />
                      </div>
                      <div className="coupon_content">
                        <h6>{promo.Title}</h6>
                        <p>{promo.Description}</p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: promo.TermsCondition,
                          }}
                        />
                      </div>
                      <span className="tm_cnt">
                        <a
                          role="button"
                          onClick={() => togglePromoCodePopUp(promo)}
                        >
                          *T &C apply
                        </a>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="emptyCartDisplay">
          <div className="emptyCart_container">
            <img
              className="emptyCartImage "
              src={CartImage}
              alt="Empty Cart img"
            />
            <div className="cart_empty_cart_wrap">
              <h5>Your cart is empty</h5>
              <p>
                Looks like you have not added anything to your cart. Go ahead &
                explore top categories.
              </p>
            </div>
            <div className="startShopping_contanier">
              <Link to={"/"} className="btn_primary">
                Start Shopping
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
